.visually-hidden {
  position: absolute;

  &[data-hidden='true'],
  &[data-show-on-focus='true']:not(:focus) {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    word-wrap: normal;
  }
}
