.heading {
  display: block;
  line-height: var(--lineHeightTitle);
  color: var(--colorTextTitle);

  &[data-level='0'] {
    letter-spacing: -0.006em;
    font-size: var(--fontSizeH0);
  }

  &[data-level='1'] {
    letter-spacing: -0.005em;
    font-size: var(--fontSizeH1);
  }

  &[data-level='2'] {
    font-size: var(--fontSizeH2);
    letter-spacing: -0.003em;
  }

  &[data-level='3'] {
    font-size: var(--fontSizeH3);
  }

  &[data-level='4'] {
    font-size: var(--fontSizeH4);
  }

  &[data-align='auto'] {
    text-align: inherit;
  }

  &[data-align='start'] {
    text-align: start;
  }

  &[data-align='center'] {
    text-align: center;
  }

  &[data-weight='regular'] {
    font-weight: var(--fontWeightRegular);
  }

  &[data-weight='medium'] {
    font-weight: var(--fontWeightMedium);
  }

  &[data-weight='bold'] {
    font-weight: var(--fontWeightBold);
  }
}
