@custom-media --mediaDesktop (max-width: 2080px);
@custom-media --mediaLaptop (max-width: 1680px);
@custom-media --mediaTablet (max-width: 1024px);
@custom-media --mediaMobile (max-width: 696px);
@custom-media --mediaMobileS (max-width: 400px);
@custom-media --mediaUseMotion (prefers-reduced-motion: no-preference);
@custom-media --mediaReduceMotion (prefers-reduced-motion: reduce);
@custom-media --mediaTouch (pointer: coarse);
@custom-media --mediaNoTouch (pointer: fine);

body {
  color-scheme: dark light;
  font-family: var(--fontStack);
  font-weight: var(--fontWeightRegular);
  background: rgb(var(--rgbBackground));
  color: var(--colorTextBody);
  width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: var(--monoFontStack);
}

::selection {
  background: rgb(var(--rgbAccent));
  color: rgb(var(--rgbBlack));
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
