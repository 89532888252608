.monogram {
  fill: var(--colorTextTitle);
  transition: fill var(--durationL) ease;
}

.monogram__highlight {
  fill: rgb(var(--rgbAccent));
  opacity: 0;
  transform: scale3d(1, 0, 1);
  transform-origin: top;
  transition: opacity 0.1s ease var(--durationM);

  @media (--mediaUseMotion) {
    & {
      transition: transform var(--durationM) var(--bezierFastoutSlowin),
        opacity 0.1s ease var(--durationM);
    }
  }

  @nest :is(a:focus, a:hover, .monogram:hover) & {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transform-origin: bottom;
    transition: opacity 0.1s ease;

    @media (--mediaUseMotion) {
      & {
        transition: transform var(--durationM) var(--bezierFastoutSlowin),
          opacity 0.1s ease;
      }
    }
  }
}
