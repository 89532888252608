.theme-toggle {
  --offset: 6px;
  --inset: calc(var(--spaceOuter) - var(--offset));

  position: fixed;
  z-index: 2048;
  top: var(--inset);
  right: var(--inset);
  transform: translate3d(0, 0, 0);

  @media (--mediaMobile), (max-height: 696px) {
    &:not([data-mobile='true']) {
      display: none;
    }
  }

  &[data-mobile='true'] {
    --offset: var(--spaceXS);
    --inset: calc(var(--spaceXL) - var(--offset));

    top: unset;
    bottom: var(--inset);
    margin-top: 0;
    margin-right: 0;
  }
}

.theme-toggle__svg {
  display: block;
}

.theme-toggle__circle {
  fill: currentColor;
  transform: scale(0.6);
  transform-origin: center;

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, fill;
      transition-duration: var(--durationL);
      transition-delay: 0s;
      transition-timing-function: var(--bezierFastoutSlowin);
    }
  }

  &[data-mask='true'] {
    fill: white;
  }

  &[data-dark='true'] {
    transform: none;
    transition-delay: 0.3s;
  }
}

.theme-toggle__mask {
  fill: black;
  transform: translate3d(100%, -100%, 0);

  @media (--mediaUseMotion) {
    & {
      transition: transform var(--durationL) var(--bezierFastoutSlowin);
      transition-delay: 0s;
    }
  }

  &[data-dark='true'] {
    transform: none;
    transition-delay: 0.3s;
  }
}

.theme-toggle__path {
  stroke: currentColor;
  fill: none;
  stroke-linecap: round;
  stroke-width: 3;
  stroke-dasharray: 7 7;
  stroke-dashoffset: 0;
  opacity: 1;

  @media (--mediaUseMotion) {
    & {
      transition-property: stroke-dashoffset, opacity;
      transition-duration: var(--durationL);
      transition-timing-function: var(--bezierFastoutSlowin);
      transition-delay: 0.3s;
    }
  }

  &[data-dark='true'] {
    stroke-dashoffset: 7;
    transition-delay: 0s;
    opacity: 0;
  }
}
